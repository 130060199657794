import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Image, Input, Row, Skeleton, message, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import activeIcon from 'assets/image/active.svg';
import agentIcon from 'assets/image/agent.svg';
import balanceIcon from 'assets/image/balance.svg';
import betIcon from 'assets/image/bet.svg';
import betuserIcon from 'assets/image/bet_user.svg';
import cashbackIcon from 'assets/image/cashback.svg';
import copyIcon from 'assets/image/copy.svg';
import depositIcon from 'assets/image/deposit.svg';
import downloadIcon from 'assets/image/download.svg';
import firstDepositIcon from 'assets/image/first_deposit.svg';
import firstDepositUserIcon from 'assets/image/first_deposit_user.svg';
import remarkIcon from 'assets/image/icon-11.svg';
import memberIcon from 'assets/image/member.svg';
import deposituserIcon from 'assets/image/multiple_deposit_user.svg';
import newuserIcon from 'assets/image/new_user.svg';
import promotionIcon from 'assets/image/promotion.svg';
import qrCodeIcon from 'assets/image/qr_code.svg';
import totalProfit from 'assets/image/total_profit.svg';
import totalProportionIcon from 'assets/image/total_proportion.svg';
import winlossIcon from 'assets/image/win_lose.svg';
import withdrawIcon from 'assets/image/withdraw.svg';
import { ViewActiveMembersTop10, ViewGameCategoryCashback } from 'components/account.component';
import { DatePickerCol, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import { QRCodeCanvas as QRCode } from 'qrcode.react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { toFormatNumber as $f, $mobile } from 'utils/common';

// 首頁 / 數據總覽

const PageMain: React.FC = () => {
  // 使用自定義hook 取得<API></API>所得到的資料
  const { data: $s } = useSite();
  const { info, mutate, permissions: $p, permissionValidating} = useAccount();
  const navigate = useNavigate();
  const [date, setDate] = useState([
    dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);
  const [isEditNickname, setIsEditNickname] = useState(false);
  const [nickname, setNickname] = useState('');

  // 綜合數據
  const { data: _reportInDownline } = $get({
    url: 'api/report/inDownline/walletStatistics',
    params: { DateType: date[2] }
  })
  const { data: _reportInfo, isValidating: infoValidating } = $get({
    url: 'api/report/info',
    params: { DateType: date[2] }
  })

  // 編輯暱稱
  const onEditNickname = () => {
    if (!nickname) return setIsEditNickname(false);
    $post({
      url: 'api/agent/nickname/update',
      send: { NickName: nickname },
      success: () => {
        message.success(i18n.t('editSuccess'))
        setIsEditNickname(false);
        mutate();
      }
    })
  }

  // 下載QRCode
  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const DataCol: React.FC<{
    span?: number; icon: any; name: string; val: string; people?: string; count?: string;
  }> = ({ span = $mobile ? 24 : 6, icon, name, val, people, count }) => {
    return (
      <Col span={span}>
        <Card className="card-shadow" bodyStyle={{ padding: '15px 20px' }} loading={infoValidating}>
          <Row align="middle" justify="space-between" style={{ height: '76px' }}>
            <Col>
              <Image src={icon} preview={false} />
            </Col>
            <Col>
              <div className="text-end" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="color-03">{name}</div>
                <div className="color-04 size-20 font-w-md">{val}</div>
                <div>
                  {people && <span>{`${people} ${i18n.t('people')} | `}</span>}
                  {count && <span>{count} {i18n.t('counts')}</span>}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }

  return (
    <>
      <Spin spinning={permissionValidating}>
        <LayoutNav />
        <Content>
          <Row align={'top'} justify={'start'} gutter={[16, 16]} className='mt-2'>
            {/* 代理基本資料 */}
            {/*window.innerWidth < 1300判斷true or false*/}
            <Col span={$mobile ? 24 : 6}>
              {/*卡片*/}
              <Card
                loading={false}
                className="card-shadow mb-1"
                headStyle={{ padding: 0, background: "linear-gradient(135deg, #2DBDE0 0%, #317CE0 100%)" }}
                bodyStyle={{ padding: 0 }}
                title={
                  <Skeleton loading={false} paragraph={{ rows: 2 }} avatar >
                    <Row style={{ padding: '1rem 1.5rem' }} gutter={[12, 12]}>
                      {/* 頭像帳號 */}
                      <Col span={24}>
                        <Row>
                          <Col span={7}>
                            <Avatar size={70} style={{ marginTop: '5px' }} icon={<UserOutlined />} />
                          </Col>
                          <Col span={17}>
                            <Row>
                              {/* 帳號 */}
                              <Col span={24} className="size-28 font-w-md color-01">
                                {info.Account}
                              </Col>
                              <Col span={24}>
                                {/*三元判斷顯示是否為編輯狀態,有不同呈現*/}
                                {isEditNickname
                                  ?
                                  <Row align="middle" gutter={[5, 5]}>
                                    <Col>
                                      <Input
                                        size="small" style={{ width: 88 }}
                                        placeholder={info.NickName as any || i18n.t('inputData')}
                                        onChange={e => setNickname(e.target.value)}
                                      />
                                    </Col>
                                    <Col>
                                      <Row gutter={[5, 5]}>
                                        <Col>
                                          <Button size="small" onClick={() => setIsEditNickname(false)}>
                                            {i18n.t('cancel')}
                                          </Button>
                                        </Col>
                                        <Col>
                                          <Button size="small" type="primary" onClick={onEditNickname}>
                                            {i18n.t('modify')}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  :
                                  <Row align="middle" gutter={[5, 5]}>
                                    <Col className="color-01 size-16">
                                      {info.NickName || '-'}
                                    </Col>
                                    <Col>
                                      <Button size="small" className="center" onClick={() => setIsEditNickname(true)}>
                                        <Image className="center" src={remarkIcon} preview={false} />
                                      </Button>
                                    </Col>
                                  </Row>
                                }
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* 餘額 */}
                      <Col span={24}>
                        <Row align="middle" justify={'space-between'}>
                          <Col span={7}>
                            <Row gutter={[5, 5]}>
                              <Col><Image src={balanceIcon} preview={false} /></Col>
                              <Col className="color-01 size-16">{i18n.t('balance')}</Col>
                            </Row>
                          </Col>
                          <Col span={11} className="color-01 size-16">
                            {$f(info.Balance)}
                          </Col>
                          <Col span={6}>
                            <Button
                              size="middle" className="color-01 custom-hover-button" style={{ background: 'none' }}
                              onClick={() => navigate('/wallet')}
                            >
                              {i18n.t('myWallet')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Skeleton>
                }
              >
                <Row style={{ padding: '1.5rem' }}>
                  {/* 推薦碼 */}
                  <Col span={24}>
                    <Row >
                      {/* 左 */}
                      <Col span={12}>
                        <Row align="middle" gutter={[5, 5]}>
                          <Col>
                            <Image src={qrCodeIcon} preview={false} />
                          </Col>
                          <Col>
                            {i18n.t('promotionCode')}
                          </Col>
                          <Col span={24} className="size-24 font-w-md">
                            {info.RefCode}
                          </Col>
                          <Col span={24}>
                            {/*複製推薦碼按鈕*/}
                            <Button
                              style={{ display: 'flex', alignItems: 'center' }}
                              icon={<Image src={copyIcon} preview={false} />}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://${$s?.Domain}/register?type=register&code=${info.RefCode}`
                                );
                                message.success(i18n.t('copySuccess'))
                              }}
                            >
                              {i18n.t('copy')}
                            </Button>
                          </Col>
                          <Col span={24}>
                            {/*下載QRCode碼按鈕*/}
                            <Button
                              style={{ display: 'flex', alignItems: 'center' }}
                              icon={<Image src={downloadIcon} preview={false} />}
                              onClick={downloadQRCode}
                            >
                              {i18n.t('download')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {/* 右 */}
                      <Col span={12}>
                        <Row justify="end">
                          <div id="myqrcode" style={{ border: '.5px solid #e3e3e3', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 5 }}>
                            <QRCode id="qrCode" value={`https://${$s?.Domain}/register?type=register&code=${info.RefCode}`}
                              size={150} level="H" bgColor='white' includeMargin />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Divider />
                  {/* 代理 */}
                  <Col span={24}>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Row align="middle" gutter={[5, 5]}>
                          <Col><Image src={agentIcon} preview={false} /></Col>
                          <Col className="size-14">{i18n.t('agent')}</Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row align="middle" justify="space-around">
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>
                                {$f(info.DownlineAgentCount as any + info.InDownlineAgentCount as any, 0)}
                              </Col>
                              <Col className="center" span={24}>{i18n.t('totalCounts')}</Col>
                            </Row>
                          </Col>
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>{$f(info.DownlineAgentCount, 0)}</Col>
                              <Col className="center" span={24}>{i18n.t('directly')}</Col>
                            </Row>
                          </Col>
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>{$f(info.InDownlineAgentCount, 0)}</Col>
                              <Col className="center" span={24}>{i18n.t('subordinate')}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Divider />
                  {/* 會員 */}
                  <Col span={24}>
                    <Row gutter={[12, 12]}>
                      <Col>
                        <Row align="middle" gutter={[5, 5]}>
                          <Col><Image src={memberIcon} preview={false} /></Col>
                          <Col className="size-14">{i18n.t('member')}</Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row align="middle" justify="space-around">
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>
                                {$f(info.DownlineMemberCount as any + info.InDownlineMemberCount as any, 0)}
                              </Col>
                              <Col className="center" span={24}>{i18n.t('totalCounts')}</Col>
                            </Row>
                          </Col>
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>{$f(info.DownlineMemberCount, 0)}</Col>
                              <Col className="center" span={24}>{i18n.t('directly')}</Col>
                            </Row>
                          </Col>
                          <Col span={7} className="center border-rd-01" style={{ padding: '5px 0px', background: '#F2F2F2' }}>
                            <Row align="middle" justify="center">
                              <Col className="center size-26" span={24}>{$f(info.InDownlineMemberCount, 0)}</Col>
                              <Col className="center" span={24}>{i18n.t('subordinate')}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={$mobile ? 24 : 18}>
              <Row align={'top'} justify={'start'} gutter={[12, 12]}>
                {/* 時間區間 */}
                <Col span={24}>
                  <Row
                    gutter={[12, 12]} justify={'space-between'}
                    style={{ paddingTop: '2px', textAlign: $mobile ? 'center' : 'start' }}
                  >
                    <DatePickerCol span={$mobile ? 24 : 9} date={date} setDate={setDate}
                       displayCom={['Radio']} defaultDateType={DATE_TYPE.thisMonth}
                    />
                  </Row>
                </Col>

                <DataCol span={$mobile ? 24 : 12} icon={cashbackIcon} name={i18n.t('totalRebate')} val={$f(_reportInfo?.Data?.TotalCommission)} />
                {/* 公式: 輸贏 + 返水 */}
                <DataCol span={$mobile ? 24 : 12} icon={totalProfit} name={i18n.t('totalEarnings')} val={$f(_reportInfo?.Data?.AgentProfit + _reportInfo?.Data?.TotalCommission)} />

                <DataCol icon={betIcon} name={i18n.t('betAmountValid')} val={$f(_reportInfo?.Data?.TotalValidBetAmount)} />
                <DataCol icon={winlossIcon} name={i18n.t('memberWinLoss')} val={$f(_reportInfo?.Data?.TotalWinLossAmount)} />
                <DataCol icon={totalProportionIcon} name={i18n.t('totalCommissionRate')} val={$f(_reportInfo?.Data?.TotalRevenueShare)} />
                <DataCol icon={promotionIcon} name={i18n.t('promotionCost')} val={$f(_reportInfo?.Data?.AgentBonusCostAmount)} />

                <DataCol icon={betuserIcon} name={i18n.t('bettingMembers')} val={$f(_reportInfo?.Data?.TotalBetMemberCount, 0)} />
                <DataCol icon={activeIcon} name={i18n.t('activeUser')} val={$f(_reportInfo?.Data?.ActiveMemberCount, 0)} />
                <DataCol icon={firstDepositUserIcon} name={i18n.t('firstDepositMembers')} val={$f(_reportInfo?.Data?.TotalFirstTimeDepositMemberCount, 0)} />
                <DataCol icon={deposituserIcon} name={i18n.t('redepositMembers')} val={$f(_reportInDownline?.Data?.SecondTimeDepositMemberCount, 0)} />
                <DataCol icon={newuserIcon} name={i18n.t('newUsers')} val={$f(_reportInfo?.Data?.NewMemberCount, 0)} />

                <DataCol icon={depositIcon} name={i18n.t('firstDepositAmount')} val={$f(_reportInfo?.Data?.TotalIsFirstDepositAmount)}
                  count={$f(_reportInfo?.Data?.TotalFirstTimeDepositMemberCount, 0)}
                />
                <DataCol icon={firstDepositIcon} name={i18n.t('depositAmount')} val={$f(_reportInfo?.Data?.TotalDepositAmount)}
                  count={$f(_reportInfo?.Data?.TotalDepositRecordsCount, 0)}
                />
                <DataCol icon={withdrawIcon} name={i18n.t('withdrawalAmount')} val={$f(_reportInfo?.Data?.TotalWithdrawAmount)}
                  people={$f(_reportInfo?.Data?.TotalWithdrawMemberCount, 0)} count={$f(_reportInfo?.Data?.TotalWithdrawRecordsCount, 0)} />

                <Col span={$mobile ? 24 : 12}>
                  <ViewGameCategoryCashback />
                </Col>
                <Col span={$mobile ? 24 : 12}>
                  <ViewActiveMembersTop10 DateType={date[2]} />
                </Col>

              </Row >

            </Col>
          </Row >
        </Content >
      </Spin>
    </>
  );
};

export default PageMain;
